<div *transloco="let t; prefix: translationScope">
    <h3>{{ t('title.result') }}</h3>

    <div class="mb-6">
        {{ t('label.totalIncome') }}
        <strong>{{ calculationResult.yearlyIncome | currency: 'EUR' }}</strong>

        @if (calculationResult.spouseExemption) {
            <div>
                {{ t('label.spouseExemption') }}
                <strong>{{ calculationResult.spouseExemption | currency: 'EUR' }}</strong>
            </div>
        }

        @if (calculationResult.childrenExemption) {
            <div>
                {{ t('label.childrenExemption', { numberOfChildren: calculationResult.numberOfChildren }) }}
                <strong>{{ calculationResult.childrenExemption | currency: 'EUR' }}</strong>
            </div>
        }
    </div>

    @if (calculationResult.yearlyIncome !== calculationResult.calculationBasis) {
        <div class="mb-6">
            {{ t('label.calculationBasis') }}
            <strong>
                {{ calculationResult.calculationBasis | currency: 'EUR' }}
            </strong>
        </div>
    }

    <div class="mb-6">
        <div>
            {{ t('label.coPaymentLimit') }}
            <strong>
                {{ t('label.amountOfTotalYearlyIncome', { amount: calculationResult.coPaymentLimitFactor | percent: percentFormat }) }}
            </strong>
        </div>

        <div>
            {{ t('label.coPaymentResultForYear', { year: calculationResult.year }) }}
            <strong>{{ calculationResult.coPayment | currency: 'EUR' }}</strong>
        </div>
    </div>

    <big-ui-button
        [buttonStyle]="'secondary'"
        [id]="'result-back'"
        class="block my-10 last:mb-0"
        (clicked)="onBackClick()">
        {{ t('label.back') }}
    </big-ui-button>
</div>
