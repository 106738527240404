import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild, inject, signal, type OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators, type FormControl, type FormGroup } from '@angular/forms';
import { JsonApiClientService, ResourceTypes } from '@big-direkt/json-api-client';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiButtonGroupComponent, type ButtonGroupDataModel } from '@big-direkt/ui/button-group';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiCurrencyInputComponent } from '@big-direkt/ui/input';
import { UiSelectComponent, type SelectOption } from '@big-direkt/ui/select';
import { UiSpinnerModule } from '@big-direkt/ui/spinner';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { ScrollMarginDirective, type ServiceToolsModel } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { EMPTY, catchError, map, tap, type Observable } from 'rxjs';
import { CoPaymentTaxonomyMapper } from '../mapper/co-payment-taxonomy.mapper';
import { type CoPaymentCalculationParametersModel } from '../models/co-payment-calculation-parameters.model';
import { type CoPaymentCalculationResultModel } from '../models/co-payment-calculation-result.model';
import { type CoPaymentCalculationTaxonomiesByYear } from '../models/co-payment-calculation-taxonomies-by-year.model';
import { type CoPaymentForm, type KeysMatching, type SpecialGroup } from '../models/co-payment-form.model';
import { type Periods } from '../models/period.model';
import { CoPaymentCalculatorService } from '../services/co-payment-calculator.service';
import CoPaymentCalculatorResultComponent from './co-payment-calculator-result.component';

@Component({
    selector: 'big-service-tool-co-payment-calculator',
    imports: [
        AsyncPipe,
        CoPaymentCalculatorResultComponent,
        FormsModule,
        NgClass,
        ReactiveFormsModule,
        ScrollMarginDirective,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAlertComponent,
        UiButtonComponent,
        UiButtonGroupComponent,
        UiCurrencyInputComponent,
        UiFormRowComponent,
        UiSelectComponent,
        UiSpinnerModule,
    ],
    templateUrl: './co-payment-calculator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoPaymentCalculatorComponent extends ServiceToolBaseComponent<CoPaymentForm> implements OnInit {
    private static readonly childrenOptionsCount = 9;

    private readonly yesLabel = 'stCoPaymentCalculator.label.yes';
    private readonly noLabel = 'stCoPaymentCalculator.label.no';

    private readonly coPaymentCalculatorService = inject(CoPaymentCalculatorService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly jsonApi = inject(JsonApiClientService);
    private readonly taxonomyMapper = inject(CoPaymentTaxonomyMapper);

    public readonly errorPhoneNumber = this.environmentService.errorPhoneNumber;
    public override translationScope = 'stCoPaymentCalculator';

    public hasDataLoadingError = false;
    public isLoading = signal(false);

    public periodOptions: SelectOption<Periods>[] = [
        { scope: 'stCoPaymentCalculator', value: 'month', key: 'label.periodMonth' },
        { scope: 'stCoPaymentCalculator', value: 'year', key: 'label.periodYear' },
    ];
    public socialWelfareOptions: ButtonGroupDataModel<boolean>[] = [
        {
            value: true,
            label: this.yesLabel,
        },
        {
            value: false,
            label: this.noLabel,
        },
    ];
    public numberOfChildrenOptions: SelectOption<number>[] = Array.from(Array(CoPaymentCalculatorComponent.childrenOptionsCount)).map((_, index) => ({
        value: index,
        key: `${index}`,
    }));

    public required = true;
    public hasToBeInsured: boolean | undefined = undefined;
    public taxonomiesByYear?: CoPaymentCalculationTaxonomiesByYear;
    public yearOptions$: Observable<never> | Observable<SelectOption<number>[]> | undefined;

    public specialFormGroups: KeysMatching<CoPaymentForm, FormGroup<SpecialGroup>>[] = ['familyIncome', 'allowances', 'otherIncome'];
    public isFieldRequired: Record<(typeof this.specialFormGroups)[number], boolean> = {
        otherIncome: false,
        allowances: false,
        familyIncome: false,
    };

    public calculationResult?: CoPaymentCalculationResultModel;

    public infoTemplates: Record<(typeof this.specialFormGroups)[number], TemplateRef<HTMLElement> | undefined> = {
        familyIncome: undefined,
        allowances: undefined,
        otherIncome: undefined,
    };

    @ViewChild('familyIncomeInfo', { read: TemplateRef })
    public set familyIncomeInfo(familyIncomeInfo: TemplateRef<HTMLElement>) {
        this.infoTemplates.familyIncome = familyIncomeInfo;
    }

    public ngOnInit(): void {
        this.isLoading.set(true);
        this.yearOptions$ = this.jsonApi.find<ServiceToolsModel>(ResourceTypes.TaxonomyTermServiceTools).pipe(
            map(rawTaxonomies => this.taxonomyMapper.mapTaxonomies(rawTaxonomies)),
            tap(taxonomies => {
                this.taxonomiesByYear = taxonomies;
            }),
            map(taxonomies => {
                this.isLoading.set(false);
                const availableYears = [...taxonomies.keys()].sort((a, b) => b - a);

                return availableYears.map(year => ({
                    key: `${year}`,
                    value: year,
                }));
            }),
            catchError(() => {
                this.isLoading.set(false);
                this.hasDataLoadingError = true;

                return EMPTY;
            }),
        );

        // TODO: General -> Check if error message overrides are needed (select dropdowns required message does not seem to be a good fit)

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.form = this.formBuilder.group({
            year: [
                new Date().getFullYear() as number | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            receivesSocialWelfare: [
                undefined as boolean | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            hasChronicIllness: [
                undefined as boolean | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            livesInMarriedHousehold: [
                undefined as boolean | undefined,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            numberOfChildren: [
                '' as number | string,
                [
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    Validators.required,
                ],
            ],
            familyIncome: this.formBuilder.group({
                amount: [undefined as number | undefined, []],
                period: [{ value: undefined as Periods | undefined, disabled: true }, []],
            }),
            allowances: this.formBuilder.group({
                amount: [undefined as number | undefined, []],
                period: [{ value: undefined as Periods | undefined, disabled: true }, []],
            }),
            otherIncome: this.formBuilder.group({
                amount: [undefined as number | undefined, []],
                period: [{ value: undefined as Periods | undefined, disabled: true }, []],
            }),
        });
    }

    public updateRequiredFieldsOnBlur(formGroupName: (typeof this.specialFormGroups)[number]): void {
        if (!this.form) {
            return;
        }

        const { amount, period } = this.form.controls[formGroupName].controls;

        this.isFieldRequired[formGroupName] = this.updateValidators(amount, period, this.form.controls.receivesSocialWelfare);
    }

    public onReceivesSocialWelfareChange(): void {
        this.updateRequiredFieldsOnBlur('familyIncome');
        this.updateRequiredFieldsOnBlur('allowances');
        this.updateRequiredFieldsOnBlur('otherIncome');
    }

    public submit(event: Event): void {
        event.preventDefault();

        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (!this.form?.valid) {
            return;
        }

        const { year, receivesSocialWelfare, hasChronicIllness, livesInMarriedHousehold, numberOfChildren } = this.form.value;

        const familyIncome = this.form.controls.familyIncome.controls.amount.value;
        const familyIncomePeriod = this.form.controls.familyIncome.controls.period.value;

        const allowances = this.form.controls.allowances.controls.amount.value;
        const allowancesPeriod = this.form.controls.allowances.controls.period.value;

        const otherIncome = this.form.controls.otherIncome.controls.amount.value;
        const otherIncomePeriod = this.form.controls.otherIncome.controls.period.value;

        this.assertNotNil(year);
        this.assertNotNil(receivesSocialWelfare);
        this.assertNotNil(hasChronicIllness);
        this.assertNotNil(livesInMarriedHousehold);
        this.assertNotNil(numberOfChildren);

        const model: CoPaymentCalculationParametersModel = {
            year,
            receivesSocialWelfare,
            familyIncome: familyIncome ?? undefined,
            familyIncomePeriod: familyIncomePeriod ?? undefined,
            allowances: allowances ?? undefined,
            allowancesPeriod: allowancesPeriod ?? undefined,
            otherIncome: otherIncome ?? undefined,
            otherIncomePeriod: otherIncomePeriod ?? undefined,
            hasChronicIllness,
            livesInMarriedHousehold,
            numberOfChildren,
        };

        this.assertNotNil(this.taxonomiesByYear);

        this.calculationResult = this.coPaymentCalculatorService.calculateCoPayment(model, this.taxonomiesByYear);
    }

    public onBackClick(): void {
        this.calculationResult = undefined;
    }

    private updateValidators(
        numberControl: FormControl<number | null | undefined>,
        periodControl: FormControl<Periods | null | undefined>,
        receivesSocialWelfareControl: FormControl<boolean | null | undefined>,
    ): boolean {
        const { value } = numberControl;
        const isRequired = !!value && receivesSocialWelfareControl.value === false;

        if (isRequired) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            periodControl.addValidators([Validators.required]);
            periodControl.enable();
        } else {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            periodControl.removeValidators([Validators.required]);
            periodControl.disable();
        }

        periodControl.updateValueAndValidity();

        return isRequired;
    }

    private assertNotNil<T>(value?: T | null): asserts value is NonNullable<T> {
        // eslint-disable-next-line no-null/no-null
        if (value === null || value === undefined) {
            throw new Error('Assertion error: Value is nil!');
        }
    }
}

export default CoPaymentCalculatorComponent;
